*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", "Noto Sans TC", sans-serif !important;
}

ul, li {
  list-style: none;
}

// highcharts styles
.highcharts-credits{
  display: none;
}

.highcharts-title{
  display: none;
}

.highcharts-legend{
  display: none;
}

.page {
  width: 210mm;
  height: 297mm;
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20mm;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: -1000%;
}

@media print {
  .page {
    margin: 0;
    box-shadow: none;
    page-break-after: always;
  }
}

// Date Picker Styles
.rdrCalendarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rdrDateRangeWrapper {
  display: flex;
  position: relative;
  margin-left: 112px;
}

.rdrMonth {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 224px;
  margin: 0 10px;
}

.rdrMonthName {
  color: #0A0A0A;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0 5px;
}

.rdrMonthAndYearWrapper {
  height: unset;
  padding-top: 0;

  .rdrNextPrevButton {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    outline: none;
    cursor: pointer;
    width: 20px;
    height: 20px;

    i {
      display: none;
    }
  }
  
  .rdrPprevButton {
    position: absolute;
    left: 0;
    top: 10px;
    background-image: url('../assets/monthPrevArrow.svg');
  }

  .rdrNextButton {
    position: absolute;
    right: 0;
    top: 10px;
    background-image: url('../assets/monthNextArrow.svg');
  }
}

.rdrMonthAndYearPickers {
  display: none;
}

.rdrWeekDays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 110%;
}

.rdrWeekDay {
  color: #0A0A0A;
}

.rdrDays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
}

.rdrDay {
  height: 32px;
  width: 32px;
  margin: .5px 0;

  .rdrDayInPreview, .rdrDayStartPreview, .rdrDayEndPreview {
    width: 32px;
    height: 32px;
    top: 0;
    bottom: 0;
  }

  &.rdrDayHovered {
    .rdrDayStartPreview, .rdrDayEndPreview {
      width: 32px;
      height: 32px;
      top: 0;
      bottom: 0;
    }
  }
}

.rdrStartEdge, .rdrEndEdge {
  width: 32px;
  height: 32px;
  top: 0;
  bottom: 0;
}

.rdrDayNumber {
  width: 32px;
  height: 32px;
  top: 0;
  bottom: 0;
  font-weight: 400;

  span {
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
  }
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: #E95076;
}

.rdrDay.rdrDayPassive .rdrStartEdge ~ .rdrDayNumber span, .rdrDay.rdrDayPassive .rdrEndEdge ~ .rdrDayNumber span {
  height: 32px;
  border-radius: 0;
  background-color: transparent;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
  height: 32px;
  color: #fff;
}

.rdrDay.rdrDayPassive .rdrInRange ~ .rdrDayNumber span {
  height: 32px;
  border-radius: 0;
  background-color: transparent;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  font-weight: bold;
  height: 32px;
  border-radius: 0;
  background-color: #FDEDF1;
  color: #0A0A0A;
}

.rdrStartEdge ~ .rdrDayNumber span,
.rdrEndEdge ~ .rdrDayNumber span
{
  font-weight: bold;
  height: 32px;
  background-color: #E95076;
}

.rdrDayDisabled {
  position: relative;
  background-color: transparent;
}

.rdrDayDisabled:not(.rdrDayToday)::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ccc;
  transform: translateY(-50%);
}

.custom-date-range {
  padding: 12px;
  border: 1px solid #52697A;
  border-radius: 8px;
}

.date-range-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.rdrDefinedRangesWrapper {
  font-size: 14px;
  width: 112px;
  padding-right: 12px;
  position: absolute;
  z-index: 200;
  top: 13px;
  bottom: 13px;
  left: 13px;
  border-right: 1px solid #D4D4D4;

  .rdrStaticRange {
    border: none;
    border-radius: 8px;
    margin-bottom: 8px;
    
    .rdrStaticRangeLabel {
      font-weight: 400;
      padding: 12px 0;
      text-align: center;
    }
  
    &:hover {
      .rdrStaticRangeLabel {
        border-radius: 8px;
        background-color: transparent;
        transition: background-color .3s;
      }
    }
  }

  .rdrStaticRangeSelected {
    color: #E95076 !important;
    border-radius: 8px;
    background-color: #FDEDF1;

    .rdrStaticRangeLabel {
      font-weight: 700;
    }
  }
}